import React from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from './TextField';

export default function Autocomplete({
	label,
	error,
	helperText,
	autoFocus,
	disabled,
	...props
}) {
	return (
		<MuiAutocomplete
			renderInput={params => (
				<TextField
					{...params}
					label={label}
					error={error}
					helperText={helperText}
					autoFocus={autoFocus}
					disabled={disabled}
				/>
			)}
			openOnFocus={autoFocus}
			{...props}
		/>
	);
}
